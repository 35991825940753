import { 
  FaExclamation, 
  FaClipboard,
  FaPen,
  FaFolderOpen } from 'react-icons/fa';
import { 
  BsBriefcaseFill, 
  BsFillCheckSquareFill,
  BsPeopleFill, } from 'react-icons/bs';
import { IoMdDocument } from "react-icons/io";
import { GiMicrophone } from "react-icons/gi";
import { images } from '../../constants';

const services = [
    {
      title: 'HR Investigations',
      icon: BsBriefcaseFill,
      quote: '"The essence of leadership is influence."',
      short_desc: [
        "Conduct thorough and confidential HR investigations to resolve workplace issues effectively.",
      ],
      description:
        `Our HR investigation services provide comprehensive and confidential examinations of workplace issues, 
        ensuring fair and unbiased resolutions. We handle a variety of cases, including harassment, discrimination, 
        and misconduct, using proven methodologies to gather facts and present findings clearly. \
        Our goal is to foster a safe and respectful work environment by addressing concerns promptly and effectively.`,
      image: `${images.description1}`
    },
  
    {
      title: 'Executive Searches',
      icon: FaExclamation,
      quote: '"Take care of your people and they will take care of you."',
      short_desc: [
        "Find and recruit top-tier executives to lead your organization with expertise and vision.",
      ],
      description:
      `Our executive search services are designed to identify and recruit high-caliber leaders who 
      can drive your organization's success. We leverage a vast network and rigorous vetting process 
      to find candidates who not only possess the required skills and experience but also align with 
      your company's culture and values. Our approach ensures a seamless integration of new executives 
      who can contribute immediately to strategic goals.`,
      image: `${images.description2}`
    },
  
    {
      title: 'Compensation Assessments',
      icon: IoMdDocument,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Analyze and structure competitive compensation packages to attract and retain top talent.",
      ],
      description:
      `Our compensation assessment services help you design and implement competitive pay structures that attract 
      and retain top talent. We conduct thorough market analyses to ensure your compensation packages are aligned 
      with industry standards and reflect your organization's goals. This includes evaluating salaries, bonuses, 
      benefits, and other incentives to create a balanced and motivating compensation strategy.`,
      image: `${images.description8}`
    },
    {
      title: 'Talent and Leadership Development Programs',
      icon: FaClipboard,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Empower your team with tailored programs that enhance skills and cultivate leadership.",
      ],
      description:
      `Our talent and leadership development programs are tailored to enhance the skills and capabilities 
      of your workforce. We offer a range of training solutions, from leadership coaching to skill-building workshops, 
      designed to develop future leaders and drive organizational performance. These programs are crafted to address 
      specific needs, ensuring that your team is equipped to meet current and future challenges.`,
      image: `${images.description4}`
    },
    {
      title: 'Employee Onboarding and Retention Initiatives',
      icon: BsPeopleFill,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Implement effective onboarding and retention strategies to build a committed and satisfied workforce.",
      ],
      description:
      `Our employee onboarding and retention initiatives are designed to create a seamless and engaging transition 
      for new hires while fostering long-term commitment. We provide comprehensive onboarding programs that integrate 
      new employees into your company culture and equip them with the necessary tools for success. Additionally, 
      we implement retention strategies that address employee satisfaction and career development, reducing turnover and enhancing morale.`,
      image: `${images.description5}`
    },
    {
      title: 'Workplace Culture Reviews',
      icon: FaFolderOpen,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Evaluate and improve your workplace culture to foster a positive and productive environment.",
      ],
      description:
      `Our workplace culture reviews offer an in-depth analysis of your organizational environment, 
      identifying strengths and areas for improvement. We assess employee engagement, communication, 
      and values alignment to provide actionable insights that help you cultivate a positive and productive workplace. 
      By enhancing your culture, we support your efforts to create a more cohesive and motivated workforce.`,
      image: `${images.description6}`
    },
    {
      title: 'Staff Training Workshops',
      icon: FaPen,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Offer engaging workshops to boost your team's skills and drive organizational success.",
      ],
      description:
      `Our staff training workshops are designed to enhance the skills and productivity of your employees. 
      We offer a variety of interactive and practical workshops tailored to your organization's needs, 
      covering topics such as communication, teamwork, and problem-solving. These sessions not only 
      improve individual performance but also contribute to overall organizational success by fostering 
      a culture of continuous learning and development.`,
      image: `${images.description7}`
    },
    {
      title: 'HR Presentation and Speaking Engagements (Request at least 30 days in advance)',
      icon: GiMicrophone,
      quote: '"Who knew a seat at the table came with unexpected stress? You deserve support in your role."',
      short_desc: [
        "Book insightful and dynamic HR presentations and speaking engagements for your events.",
      ],
      description:
      `Our HR presentation and speaking engagement services provide insightful and dynamic talks 
      on a range of HR-related topics. Whether you need a keynote speaker for a conference or an 
      expert for a workshop, our presentations are designed to inform, inspire, and engage your audience. 
      We cover the latest trends, best practices, and innovative solutions in HR, helping you stay ahead 
      in a rapidly evolving field. Please request these engagements at least 30 days in advance to ensure availability.`,
      image: `${images.description3}`
    },
];

const clients = [
    {
      title: 'Birmingham Business Alliance (BBA)',
      image: `${images.bbaLogo}`
    },
    {
      title: 'City of Birmingham',
      image: `${images.bhamLogo}`
    },
    // {
    //   title: 'Bradley Arant',
    //   image: `${images.bradleyLogo}`
    // },
    {
      title: 'Encompass',
      image: `${images.encompassLogo}`
    },
    {
      title: 'Housing Authority Birmingham District (HABD)',
      image: `${images.habdLogo}`
    },
    {
      title: 'Innovate Alabama',
      image: `${images.innovateLogo}`
    },
    {
      title: 'Protective Life',
      image: `${images.protectiveLogo}`
    },
    {
      title: 'UAB Medicine',
      image: `${images.uabLogo}`
    },
    {
      title: `The Women's Foundation of Alabama`,
      image: `${images.wfaLogo}`
    },
    {
      title: 'YWCA',
      image: `${images.ywcaLogo}`
    },
    {
      title: 'Southern Research',
      image: `${images.srLogo}`
    },
];

const sortedClients = clients.sort((a, b) => {
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  
  return 0;
});
  
export { services, sortedClients };