import React from 'react';
import './About.scss';
import { accolades } from './accolades';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { BsArrowRightSquare } from 'react-icons/bs';

const About = () => {
  return (
    <>
      <div className='app_about' id='about' />
      <div className='app_about_header_container'>
        {/* <motion.div className='app_about_header'>
          {accolades.map((accolade) => (
            <div className='app_accolades'>
              <h3 className='accolades_value'>{accolade.value}</h3>
              <p className='accolades_title'>{accolade.title}</p>
            </div>
          ))}
        </motion.div> */}
          <div className='app_about_title'>
            <p className='title'>
              Who is Alesia Jones?
            </p>
          </div>
      </div>

      <div className='app_about_body' id='about'>
        <div className='body_img'>
          <img src={images.headshot} alt='about2' />
          <p className='img_tag'>Want to learn more about me? Click <a href="/about">here</a></p>
        </div>
        <div className='app_about_container'> 
          <div className='app_about_content'>
            <div className='content_tag'>
              <h3 className='tag_title'>About Me</h3>
            </div>
            <div className='tag_header'>
              <h2>Alesia M. Jones, MBA</h2>
              <h4>FOUNDER & PRINCIPAL CONSULTANT</h4>
            </div>
            <h2 className='content_title'>Seasoned HR Executive</h2>
            <p className='body_content'>
              Alesia M. Jones is the principal consultant and owner of AMJ HR Solutions, LLC. 
              She founded the consulting firm in January 2022. Alesia brings more than 30+ years of HR leadership experience from multiple industries, 
              having recently retired as the Chief Human Resources Officer (CHRO) at the University of Alabama at Birmingham (UAB). 
            </p>
            <div className='schedule_button'>
              <a href="/contact" >
                Contact Me 
                <BsArrowRightSquare />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
