import { React, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { FaPhoneAlt } from 'react-icons/fa';
import { images } from '../../constants';
import { Contact } from '../../components';
import './ContactPage.scss';
// import { slideIn } from '../utils/motion';

// oG66Itl3aG_vIuO4n
// service_xi6kjuh
// template_1s6tunc

const ContactPage = () => {
  return (
    <div className='app_contact_page'>
      <Contact />

      <div className="app_contact_tel">
        <a href="tel:+12052010709"><FaPhoneAlt /> Click here to call us</a>
      </div>
    </div>
  )
}

export default ContactPage;