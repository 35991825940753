import { React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../../utils/motion';
import { urlFor, client } from '../../utils/client';
import './Feedback.scss';
import { images } from '../../constants';



//Below is the API call to the Sanity client

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  
  useEffect(() => {  
    const query = '*[_type == "testimonials"]';
  
    client.fetch(query).then((data) => {
      setTestimonials(data);
    })
  }, []);


//This is the actual component for each testimonial

return (
  <>
    {testimonials.length === 0 ? (
      <div className="feedback_empty_header">
        <h1>Check back later to see what our clients say about our services!</h1>
      </div>
    ) : (
      testimonials.map((testimonial, index) => ( 
        <motion.div 
          variants={fadeIn("", "spring", index * 0.5, 0.75)}
          className='app_feedback_card'
        >
          <p className='quotes'>"</p>
          
          <div className='card_body_container'>
            <p className='card_body_content'>{testimonial.feedback}</p>

            <div className='card_body_author_container'>
              <div className='card_body_author'>
                <p className='author'>
                  <span>@</span> {testimonial.name}
                </p>
                <p className='company'>
                  {testimonial.position} of {testimonial.company}
                </p>
              </div>

              <img 
                // src={urlFor(testimonial.imgUrl)} 
                src={images.amjlogo}
                alt={testimonial.name}
                className='card_img'
              />
            </div>      
          </div>

        </motion.div>
      ))
    )}
  </>
  )
};

  const Feedback = () => {
    return (
      <div className='app_wrap' id='feedback'>
        <div className="app_feedback">
          <div className="app_feedback_header_container">
            <motion.div
              variants={textVariant}
            >
              {/* <p className="app_feedback_subheader">What people are saying...</p> */}
              <h4 className="app_feedback_header">Testimonials.</h4>
            </motion.div>
          </div>
          <div className="app_feedback_section">
              <Testimonials />
          </div>
        </div>
      </div>
  )
}

export default Feedback
