import React from 'react';
import { About } from '../../components';
import { images } from '../../constants';
import './AboutPage.scss';

const AboutPage = () => {
  return (
    <div className='about_page_container'>
      <div className='about_page_title'>
        <p className='title'>Who is Alesia M. Jones?</p>
      </div>

      <div className='about_page_content'>
        <div className='about_img_container'>
          <img src={images.headshot} alt="about_img" />
          {/* <div className='about_credentials'>
            <div className='credentials box'>
              <h2>Credentials:</h2>
              <p>Licensed Marriage and Family Therapist | Professional Scrum Master</p>
            </div>
            <div className='specialties_box'>
              <h2>Specialties:</h2>
              <p>My expertise is in leadership coaching, trauma and crisis management</p>
            </div>
          </div> */}
        </div>
        <div className='about_textbox'>
          <ul className='about_text'>
            <li>
              Alesia M. Jones is the principal consultant and owner of AMJ HR Solutions, LLC. She founded the consulting firm in January 2022. 
              Alesia brings more than 30+ years of HR leadership experience from multiple industries, having recently retired as the Chief Human Resources Officer (CHRO) at the University of Alabama at Birmingham (UAB). 
              UAB is a world-renowned research university and health care center with more than 24,000 academic, administrative, medical faculty, maintenance, and operational staff. 
              Alesia has led an HR team of as many as 130 professionals who worked in both consultative and
              transactional service capacities for the academic, research, service, and health care units throughout the institution. Alesia&apos;s team was responsible for delivering all core HR services including talent acquisition, 
              compensation & benefits, organizational development and learning. In 2023, UAB and the COLLAT School of Business honored Alesia and all her hard work by renaming the HR Management scholarship to the Alesia M. Jones HR Endowed Scholarship.
            </li>
            <li>
              In addition to UAB, Alesia has served in executive HR positions at BE&K, Inc., and BellSouth. 
              She also served as an Adjunct Professor for UAB in the School of Business and occasionally returns to the lecture hall as an instructor and guest speaker for college-level Human Resource Management topics. 
              Alesia is a member of the Society for Human Resources Management, served as a member of the Governing Body for the southeastern regional HR Leadership Summit, 
              and is professionally affiliated with World at Work, Birmingham Area Compensation Association, and Alabama College and University Personnel Association.
            </li>
            <li>
              Alesia has an MBA from UAB, and a BS from The University of Alabama. She is a certified Compensation and Benefits professional. 
              She is active in the Birmingham community and civic organizations such as The Rotary Club of Birmingham, 
              The Rotary Club of Birmingham Foundation, The HUB Community Development Corporation, The American Cancer Society, 
              and The United Way of Central Alabama, Inc. She has served on various community boards, such as McWane Science Center, 
              The Women&apos;s Fund of Greater Birmingham, Railroad Park Foundation, Oasis of Hope Counseling for Women and Children, 
              and the Women&apos;s Exchange.
            </li>
          </ul>
        </div>

        <div className="app_about_scholarship">
          <div className="app_about_scholarship-title">
            <h4 className='title'>Alesia M. Jones HR Endowed Scholarship</h4>
            <p className='subtitle'>Paying it forward through a scholarship:</p>
          </div>
          <div className="app_about_scholarship-body">
            <ul className='about-text'>
              <li>
                Alesia M. Jones retired after spending 28 years at UAB, including her final 12 as the institution&apos;s senior HR leader (CHRO). 
                She helped launch several monumental initiatives at UAB, in addition to creating and helping raise funds for a scholarship for UAB students studying to earn a degree in HR.
              </li>
              <li>
                Jones found her career in HR to be more fulfilling than she could have foreseen. While at UAB, 
                she had the idea of creating an endowed scholarship to "pay it forward" for all the mentorship she received during her career.
              </li>
              <li>
                "I wanted to find a way to give back because of the career and the field that had been so special to me and had created doors 
                I didn't even know were possibilities for me and provided the opportunities to work with all of these amazing leaders at UAB," Jones said.
              </li>
              <li>
                Jones made the initial gift to create the HR Endowed Scholarship in the Collat School of Business, continues to provide an annual gift, 
                and left a bequest in her will to support the fund. To reach the endowment level, she contacted her peers to ask them to support the scholarship. 
                Many of them helped, and the scholarship was quickly endowed.
              </li>
              <li>
                Two years after she retired, UAB and the Collat School of Business honored Jones and all her hard work by renaming the scholarship to the Alesia M. Jones HR Endowed Scholarship.
              </li>
              <br/>
              <li>
                If you would like to contribute to this important scholarship, please see the donation link below. 
              </li>
              <li>

              </li>
              <li>
                <a href="https://go.uab.edu/alesiajonesscholarship">Donate to the Alesia M. Jones HR Endowed Scholarship</a>
              </li>
            </ul>
          </div>

        </div>
      </div>

      
    </div>

    
  )
}

export default AboutPage
