import { React, useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../../utils/motion';
import { urlFor, client } from '../../utils/client';
import { CiMail } from "react-icons/ci";
import './OpeningsPage.scss';
import { images } from '../../constants';



//Below is the API call to the Sanity client

const Openings = () => {
  // const website = websiteUrl.split('https://').pop().split('/')[0];
  const [openings, setOpenings] = useState([]);
  
  useEffect(() => {  
    const query = '*[_type == "openings"]';
  
    client.fetch(query).then((data) => {
      setOpenings(data);
    })
  }, []);


//This is the actual component for each opening

return (
  <>
    {openings.length === 0 ? (
      <div className="openings_empty_header">
        <h1>Check back later to see more active searches.</h1>
      </div>
    ) : (
      openings.map((opening, index) => ( 
        <motion.div 
          variants={fadeIn("", "spring", index * 0.5, 0.75)}
          className='app_openings_card'
        >
          <p className='quotes'>"</p>
          
          <div className='card_body_container'>
            <p className='card_body_content'>{opening.jobTitle}</p>
  
            <div className='card_body_author_container'>
              <div className='card_body_author'>
                <p className='author'>
                  <span>@</span> {opening.company}
                </p>
                <p className='company'>
                  {opening.position} / {opening.location}
                </p>
                <p className='company'>
                  {opening.jobType} Position
                </p>
              </div>
  
              <img 
                // src={urlFor(opening.imgUrl)} 
                src={images.amjlogo}
                alt={opening.name}
                className='card_img'
              />
              <a href="mailto:applynow@amjhrsolutions.org">Click here to apply <CiMail /></a>
            </div>      
          </div>
  
        </motion.div>
      ))
    )}
  </>
)
};

const OpeningsPage = () => {
  return (
    <div className='app_wrap' id='openings'>
      <div className="app_openings">
        <div className="app_openings_header_container">
          <motion.div
            variants={textVariant}
          >
            {/* <p className="app_openings_subheader">What people are saying...</p> */}
            <h4 className="app_openings_header">Active Searches.</h4>
            <p className='app_openings_subheader'>
              If interested in a position that we are searching for, please select "Click here to apply" on the corresponding tile to
              send an email. In the email please include your name, contact information, position interest in, and an updated resume and cover letter (if applicable).
            </p>
          </motion.div>
        </div>
        <div className="app_openings_section">
            <Openings />
        </div>
      </div>
    </div>
  )
}

export default OpeningsPage;
