import React from 'react';
import './Description.scss';
import { services } from '../Services/services';
import { images } from '../../constants';

const Description = () => {
  return (
    <div className='app_description' id='description'>
      <div className='app_description_header'>
        {/* <p className='description_subheader'>What do each of these mean?</p> */}
        <h2 className='desription_header'>What do each of these mean?</h2>
      </div>

      <div className='app_description_container'>
        {services.map((service, i) => {
          return (
            <div className='description_item'>
              <img 
                src={service.image} 
                alt="desc_image" 
                key={i}
                />
              <div className="text-box">
                <h3 className='services_title'>{service.title}</h3>
                <p className='description_content'>{service.description}</p>
              </div>
            </div>
          )
        })}
          {/* <div className='description_item'>
            <img src={images.leadershipcoaching} alt="service_image" />
            <div className='text_box'>
              <h3 className='services_title'>Leadership Coaching</h3>
              <p className='description_content'>
                Have you ever considered that the key to being a successful leader is genuinely caring for the people you lead? 
                It might sound simple, but love is the secret ingredient of effective leadership. When you lead with love, 
                you inspire trust, loyalty, and dedication from your team. It's not just about being in charge or making tough decisions. 
                It's about building relationships, fostering a positive work environment, and showing your team that you value and appreciate them. 
                Our range of services helps women become the best leaders they can be. The essence of leadership is influence. 
                It takes a certain level of skill and charisma to become an influential leader, but it is a trait that can be developed and honed over time.<br/>
                The best leaders can ultimately inspire and empower their teams to reach new heights. With expertise in leadership coaching, personal development, 
                people management, effective communication, and God as the foundation, our team is well-equipped to help women in every stage of their leadership journey. 
                Making It Happen Love Foundation empowers women with the tools to lead from a place of love. As a Licensed Marriage and Family Therapist, Roni'ka 
                knows firsthand how pain, trauma, and hurt can hold women back from achieving their full potential as leaders. That's why our team provides comprehensive coaching<br/> 
                and consulting services designed to help women overcome these obstacles and confidently move forward with ease. Our range of services helps women become the best leaders they can be. 
                The essence of leadership is influence.  It takes a certain level of skill and charisma to become an influential leader, but it is a trait that can be developed and honed over time. 
                The best leaders can ultimately inspire and empower their teams to reach new heights. Our range of services helps women become the best leaders they can be. 
                The essence of leadership is influence. It takes a certain level of skill and charisma to become an influential leader, but it is a trait that can be developed and honed over time. 
                The best leaders can ultimately inspire and empower their teams to reach new heights.
              </p>
            </div>
          </div>
          <div className='description_item'>
            <img src={images.crisismanagement} alt="service_image" />
            <div className='text_box'>
              <h3 className='services_title'>Crisis Management Consulting</h3>
              <p className='description_content'>
                At Making It Happen Love Foundation, we know that taking care of your team is critical to success. 
                That's why we offer crisis management consulting and an employee assistance program to help leaders 
                establish a healthy work-life balance and prioritize the well-being of their team. We are committed 
                to supporting organizations in every way possible, giving them the tools to lead their teams to greatness. 
                Because at the end of the day, your dream is only as strong as your team - and we're here to help you make it happen.
              </p>
            </div>
          </div>
          <div className='description_item'>
            <img src={images.csuite} alt="service_image" />
            <div className='text_box'>
              <h3 className='services_title'>C-Suite Therapist</h3>
              <p className='description_content'>
                For women in C-Suite positions, we provide numerous 
                benefits to CEOs and other high-level executives feeling overwhelmed and stressed. 
                We offer stress management, healthy relationship building, effective communication, 
                and boundary-setting support to help them navigate the unique challenges of leadership. 
                By obtaining therapy, individuals find more balance in their personal and professional lives. 
                Treatment leads to improved decision-making, increased productivity, and better overall well-being. 
                Additionally, taking care of oneself sets a positive example for employees and helps create a healthier and more positive work culture.
              </p>
            </div>
          </div> */}
      </div>
    </div>
  )
}

export default Description
