import { React, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { images } from '../../constants';
import './Contact.scss';
// import { slideIn } from '../utils/motion';

// oG66Itl3aG_vIuO4n
// service_xi6kjuh
// template_1s6tunc

const Contact = () => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  // const { name, email, message } = formData;
  // const handleChangeInput = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({ ...FormData, [name]: value });
  // }
  const handleChange = (e) => {
    const { name, value } = (e.target);

    setForm({ ...form, [name]: value })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
        'service_xi6kjuh', 
        'template_w9xt1n8', 
        {
          from_name: form.name,
          to_name: 'AMJ HR Solutions',
          from_email: form.email,
          to_email: 'applynow@amjhrsolutions.org',
          message: form.message
        },
        'oG66Itl3aG_vIuO4n'
      )
      .then(() => {
        setLoading(false);
        alert('Thank you! I will be in touch shortly!');

        setForm({
          name: '',
          email: '',
          message: '',
        }) 
       }, (error) => {
          setLoading(false)

          console.log(error);

          alert('Uh oh! Something went wrong.')
      })
  }
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });


  return (
    <>
      {!isFormSubmitted ? (
        <div className='app__contact'>
          <div className="contact_title-container">
            <h2 className='contact-title'>
              Contact Us
            </h2>
          </div>
          <div className='app_contact_container'>
            <div className='app_contact_form app__flex'>
              <div className='app__flex'>
                <input
                  className='p-text'
                  type="text"
                  placeholder="What's your name?"
                  name='name'
                  value={form.name}
                  onChange={handleChange}
                />
              </div>
              <div className='app__flex'>
                <input
                  className='p-text'
                  type="text"
                  placeholder='Your E-mail'
                  name='email'
                  value={form.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <textarea
                  className='p-text'
                  placeholder='Your Message'
                  value={form.message}
                  name="message"
                  onChange={handleChange}
                />
              </div>
              <button
                type='button'
                className='p-text'
                onClick={handleSubmit}
              >
                {loading ? 'Sending' : 'Send Message'}
              </button>
            </div>
            <div className="app_contact_-logo">
              <img src={images.amjlogo} alt='logo' />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className='head-text'>
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
}

export default Contact;