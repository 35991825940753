import React from 'react';
import HeaderOverlay from './HeaderOverlay';
import { images } from '../../constants';
import { motion } from 'framer-motion';
import { slideIn, textVariant } from '../../utils/motion';
import './Header.scss';
import { BiMessageCheck } from 'react-icons/bi';
import { BsArrowUpRight } from 'react-icons/bs';

const Header = () => {
  return (
    <>
      <motion.div 
        className='app_header' 
        id='home'
      >
        <div className='app_header_banner'>
          <div className='app_header_banner_body'>
            {/* <h3 className='top'>HR Problems Need</h3>
            <h3 className='middle'>AMJ HR Solutions</h3> */}
          </div>
          <div className="app_header_banner-img">
            <img src={images.amjlogo} alt="logo" />
          </div>
          <div className='app_header_banner_invite'>
            <h4 className='subtitle-text'>Explore More.</h4>
            <div className='contact-btn'>
              <a href="/contact">Contact Me</a>
            </div>
          </div> 
        </div>

        <div className='app_header_img'>
          <img 
            className='header_img' 
            src={images.boardroom}  
            alt={images.boardroom} 
          />
          {/* <div className='app_header_msg'>
            <h3 className='subtitle-text'>I'm glad you're here. It's time.</h3>
          </div>
          <div className='app_header_comment'>
              <div className='percent'>
                <p className='subtitle-text'>98% Positive Feedback</p>
                  <div className='percent_bubble'>
                    <p>100%</p>
                    <p>Coaching Efficiency</p>
                  </div>
              </div>
              <img
                src={images.chat}
                alt={images.messageicon}
                className='comment_img'
              />
          </div> */}
        </div>
        <div className="app_employment_invite">
          <h3 className='top'>HR Problems Need</h3>
          <h3 className='middle'>AMJ HR Solutions</h3>
          {/* <h1 className='app_employment_title'>
            We have just the right position for you...
          </h1>
          <div className="employment_banner">
            <div className='employment-text'>
              <h2 className='employment_banner-title'>View our active searches here</h2>
            </div>
            <div className="employment-arrow">
              <a href="/openings"><BsArrowUpRight /></a>
            </div>
          </div> */}
        </div>
      </motion.div>
  </>
  )
}

export default Header
