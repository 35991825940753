import React from 'react';
import { Why } from '../../components';
import { images } from '../../constants';
import './WhyPage.scss';
import { sortedClients } from '../../components/Services/services';

const WhyPage = () => {
  return (
    <div className='why_page_container'>
      <div className='why_page_title'>
        <p className='title'>Why Hire AMJ HR Solutions</p>
        <p className='title'>as Your HR Consultant?</p>
      </div>

      <div className='why_page_content'>
        <div className='why_img_container'>
          <img src={images.aboutPhoto} alt="why_img" />
          {/* <div className='about_credentials'>
            <div className='credentials box'>
              <h2>Credentials:</h2>
              <p>Licensed Marriage and Family Therapist | Professional Scrum Master</p>
            </div>
            <div className='specialties_box'>
              <h2>Specialties:</h2>
              <p>My expertise is in leadership coaching, trauma and crisis management</p>
            </div>
          </div> */}
        </div>
        <div className='why_textbox'>
          <div>
            <p className='text'>AMJ HR Solutions supports organizations in:</p>
          </div>
          <ul className='why_points'>
            <li>
              Achieving people focused results
            </li>
            <li>
              Managing organizational dynamics, and
            </li>
            <li>
              Juggling the processes to acquire the best talent
            </li>
          </ul>
          <ul className='why_text'>
            <li>
              HR professionals ensure the company culture is consistent through recruitment to offboarding. 
              Being at the center of employee performance, HR professionals also help employees understand and 
              reflect their organizational culture in the way they work.
            </li>
            <li>
              HR consultants brings industry-specific knowledge, skills, and experience, which enables the company to deliver highly effective results. 
              The core benefit of hiring an HR consultant is that the company can engage in daily tasks without worrying about the functioning of operations. 
              AMJ HR Solutions, LLC is renowned in providing innovative and proactive HR solutions customized for organization's needs.
            </li>
            <li>
              Since founding AMJ HR Solutions, LLC, Alesia has worked with several companies and organizations within the state of Alabama and the southeast. 
              Alesia has provided a variety of HR consulting services ranging from HR Investigations, Compensation Assessments, 
              Talent and Leadership Development programs, Workplace Culture Reviews, and Executive Searches. 
              AMJ HR Solutions, LLC's goal is to exceed client&apos;s expectations by providing informed and objective services. 
            </li>
          </ul>
        </div>
      </div>

      <div className="why_page_clients">
        <div className="clients_title">
          <h2 className='title'>
            Clients
          </h2>
          <p className='textbox'>
            For your HR problems, AMJ HR Solutions provides answers across organization size, location and beyond. <br /> Our clients range from start-ups and small businesses to large employers across a variety of industries. <br />
            A few are listed below.
          </p>
        </div>

        <div className='client_container'>
            {sortedClients.map((client, i) => {
              return (
                <div className='client_item'>
                  <img 
                    src={client.image} 
                    alt="client_image" 
                    key={i}
                    />
                  <div className="text-box">
                    <h3 className='services_title'>{client.title}</h3>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>

    
  )
}

export default WhyPage;
