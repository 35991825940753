import React from 'react';
import './Why.scss';
import { accolades } from './accolades';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { BsArrowRightSquare } from 'react-icons/bs';

const Why = () => {
  return (
    <>
      <div className='app_why' id='why' />
      <div className='app_why_header_container'>
        {/* <motion.div className='app_why_header'>
          {accolades.map((accolade) => (
            <div className='app_accolades'>
              <h3 className='accolades_value'>{accolade.value}</h3>
              <p className='accolades_title'>{accolade.title}</p>
            </div>
          ))}
        </motion.div> */}
          <div className='app_why_title'>
            <p className='title'>
              Why us?
            </p>
          </div>
      </div>

      <div className='app_why_body' id='why'>
        <div className='body_img'>
          <img src={images.aboutHome} alt='about' />
          {/* <p className='img_tag'>Want to learn more about me? Click <a href="/whyamj">here</a></p> */}
        </div>
        <div className='app_why_container'> 
          <div className='app_why_content'>
            <div className='content_tag'>
              <h3 className='tag_title'>Why AMJ?</h3>
            </div>
            <div className='tag_header'>
              <h2>Why hire a HR Consultant? Why AMJ HR Solutions, LLC?</h2>
              {/* <h4>FOUNDER & CEO</h4> */}
            </div>
            {/* <h2 className='content_title'>Who is behind this?</h2> */}
            <p className='body_content'>
              Human Resources as an entity is very complex. Most companies have teams that handle this necessary sector of a business, but it can be daunting.
              There are profesionals that have years of experience in streamlining processes to make this area easier, and that is exactly what AMJ brings, plus excellence.
            </p>
            <div className='schedule_button'>
              <a href="/whyamj" >
                Click here to learn more
                {/* <BsArrowRightSquare /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Why;
