import React from 'react';
import { Feedback } from '../components';

const FeedbackPage = () => {
  return (
    <div>
      <Feedback />
    </div>
  )
}

export default FeedbackPage
