import React, { useState } from 'react';
import { images } from '../../constants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { GrLinkedinOption } from 'react-icons/gr';
import { FaFacebookF, FaInstagram, FaYoutube, FaPhoneAlt } from 'react-icons/fa';
import { CiMail } from "react-icons/ci";
import { RxDividerVertical } from 'react-icons/rx';
import { motion } from 'framer-motion';
import './Navbar.scss';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>
      <div className='app_navbar'>
        <div className='navbar_img'>
          <div className='nav-img'>
            <img src={images.amjlogo} alt='logo' />
          </div>
          <div className='nav-text'>
            <p>AMJ HR Solutions</p>
          </div>
        </div>
        <ul className='navbar_items'>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About Me</a></li>
          <li><a href='/services'>Services</a></li>
          <li><a href='/whyamj'>Why AMJ?</a></li>
          {/* <li><a href='/feedback'>Feedback</a></li> */}
          <li><a href='/openings'>Active Searches</a></li>
          {/* <li><a href='#resources'>Resources</a></li> */}
          <li><a href='/contact'>Contact</a></li>
        </ul>
        <hr />
        <div className='navbar_socials'>
          <a href="https://www.linkedin.com/in/alesia-jones-700248a/">
            <GrLinkedinOption className='linkedin' />
          </a>
          <a href="mailto:applynow@amjhrsolutions.org">
            <CiMail className='instagram' />
          </a>
          {/* <a href="https://www.linkedin.com/in/sherone-ruggs-hubbard-04006b1b5/">
            <FaYoutube className='youtube' />
          </a> */}
        </div>
      </div>
      
      <div className='app_menu'>
        <div className='menu_img'>
          <img src={images.amjlogo} alt='logo' />
          <p>AMJ HR Solutions</p>
        </div>
        <HiMenuAlt4 onClick={() => setToggleMenu(true)} />
          {toggleMenu && (
            <motion.div
              className='menu_overlay'
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
            >
              <HiX className='overlay_close' onClick={() => setToggleMenu(false)} />
              <div className='menu_container'>
                <ul className='menu_items'>
                  <li><a href='/'>Home</a></li>
                  <li><a href='/about'>About Me</a></li>
                  <li><a href='/services'>Services</a></li>
                  <li><a href='/whyamj'>Why AMJ?</a></li>
                  {/* <li><a href='/feedback'>Feedback</a></li> */}
                  <li><a href='/openings'>Active Searches</a></li>
                  {/* <li><a href='#resources'>Resources</a></li> */}
                  <li><a href='/contact'>Contact</a></li>
                </ul>
                <hr />
                <div className='menu_socials'>
                  <a href="https://www.linkedin.com/in/alesia-jones-700248a/">
                    <GrLinkedinOption className='linkedin' />
                  </a>
                  <a href="mailto:applynow@amjhrsolutions.org">
                    <CiMail className='email' />
                  </a>
                  <a href="tel:+12052010709">
                    <FaPhoneAlt className='phone' />
                  </a>
                  <p>AMJ HR Solutions</p>
                </div>
              </div>
            </motion.div>
          )}
      </div>
    </>
  )
}

export default Navbar
