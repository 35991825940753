import bgoverlay from '../assets/bgoverlay.png';
import user2 from '../assets/user2.png';
import art from '../assets/art.png';
import contactus from '../assets/contactus.jpg';
import crisismanagement from '../assets/crisismanagement.jpg';
import csuite from '../assets/csuite.jpg';
import feedback from '../assets/feedback.jpg';
import chat from '../assets/chat.svg';
import homepage from '../assets/homepage.jpg';
import home from '../assets/home.png';
import headshot from '../assets/headshot.jpeg';
import amjlogo from '../assets/amjlogo.png';
import amjlogo_green from '../assets/amjlogo_green.png';
import aboutPhoto from '../assets/aboutPhoto.jpeg';
import boardroom from '../assets/boardroom.jpg';
import aboutHome from '../assets/about-home.jpg';
import aboutHome2 from '../assets/aboutHome2.jpg';
import description1 from '../assets/description1.jpg';
import description2 from '../assets/description2.jpg';
import description3 from '../assets/description3.jpg';
import description4 from '../assets/description4.jpg';
import description5 from '../assets/description5.jpg';
import description6 from '../assets/description6.jpg';
import description7 from '../assets/description7.jpg';
import description8 from '../assets/description8.jpg';
import bbaLogo from '../assets/bba-logo.jpg';
import bhamLogo from '../assets/bham-logo.jpg';
import bradleyLogo from '../assets/bradley-logo.jpg';
import encompassLogo from '../assets/encompass-logo.jpg';
import habdLogo from '../assets/habd-logo.jpg';
import innovateLogo from '../assets/innovate-al.png';
import protectiveLogo from '../assets/protective-logo.png';
import uabLogo from '../assets/uab-logo.png';
import wfaLogo from '../assets/wfa-logo.png';
import ywcaLogo from '../assets/ywca-logo.png';
import srLogo from '../assets/sr-logo.jpg';

export default {
  aboutHome,
  aboutHome2,
  boardroom,
  amjlogo,
  amjlogo_green,
  aboutPhoto,
  chat,
  bgoverlay,
  user2,
  art,
  contactus,
  crisismanagement,
  csuite,
  feedback,
  homepage,
  home,
  headshot,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  bbaLogo,
  bhamLogo,
  bradleyLogo,
  encompassLogo,
  habdLogo,
  innovateLogo,
  protectiveLogo,
  uabLogo,
  wfaLogo,
  ywcaLogo,
  srLogo
}