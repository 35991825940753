import React from 'react';
import './Services.scss';
import { services } from './services';
import { BsArrowRightSquare } from 'react-icons/bs';
import { images } from '../../constants';



const Services = () => {
  return (
    <div className='app_services' id='services'>
        <div className='app_services_banner'>
            <div className='services_tag'>
              <h3 className='services_tag_title'>
                  Services
              </h3>
            </div>
            {/* <h1 className='services_title'>
              Helping women heal from workplace trauma, find their voice, <br /> and empower them to lead with love.
              </h1> */}
          <h2 className='services_container_title'>Here's what we specialize in</h2>
        </div>
        
        <div className='app_services_container'>
            {services.map((service, i) => {
              const Icon = service.icon;
                return (
                  <div className='service_item'>
                    <div className='service_icon'>
                        <Icon style={{ width: '60', height: '60', color: 'teal' }} />
                    </div>
                    <h3 className='services_title'>{service.title}</h3>
                    <p className='services_quote'>{service.short_desc}</p>
                    <ul className='services_points'>
                        <li 
                          key={i}
                        >
                          {service.points}
                        </li>
                    </ul>
                    {/* <div className='schedule_button'>
                      <a href="/services">
                        Learn More 
                        <BsArrowRightSquare />
                      </a>
                    </div> */}
                  </div>
                );
            })}
        </div>
    </div>
  )
}

export default Services;
