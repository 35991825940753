import React from 'react';
import { Description } from '../components';

const ServicesPage = () => {
  return (
    <div>
      <Description />
    </div>
  )
}

export default ServicesPage
