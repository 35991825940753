import './index.scss';
import { Navbar, Header, Resources, Footer, About, Services, Description, Feedback } from './components';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/aboutpage/AboutPage';
import ServicesPage from './pages/ServicesPage';
import WhyPage from './pages/whypage/WhyPage';
import FeedbackPage from './pages/FeedbackPage';
import OpeningsPage from './pages/currentOpenings/OpeningsPage';
import ContactPage from './pages/contactPage/ContactPage';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/whyamj" element={<WhyPage />} />
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/openings" element={<OpeningsPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      {/* <Resources /> */}
      <Footer />
    </div>
  )
}
export default App;

