import React from 'react';
import { Navbar, Header, Resources, About, Services, Feedback, ContactFooter } from '../components';
import Why from '../components/Why/Why';


const HomePage = () => {
  return (
    <div>
      <Header />
      <About />
      <Why />
      <Services />
      <ContactFooter />
    </div>
  )
}

export default HomePage
