import React from 'react';
import { createClient } from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';


export const client = createClient({
    projectId: 'o7u09u17',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
})


const builder = ImageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);