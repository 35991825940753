import React from 'react';
import './Footer.scss';
import { images } from '../../constants';
import { GrLinkedinOption } from 'react-icons/gr';
import { FaFacebookF, FaInstagram, FaYoutube, FaPhoneAlt } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { BsArrowUpRight } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='app_footer' id='footer'>

      <div className='app_footer_nav'>
        {/* <div className='logo_container'>
          <h3 className='contact_nav_header'>
            Roni'ka Cotton, LMFT
          </h3>
        </div> */}

        <div className='items_container'>
          <ul className='nav_items'>
            <li><a href='/home'>Home</a></li>
            <li><a href='/about'>About Me</a></li>
            <li><a href='/services'>Services</a></li>
            <li><a href='/whyamj'>Why AMJ?</a></li>
            <li><a href='/openings'>Active Searches</a></li>
            {/* <li><a href='/feedback'>Feedback</a></li> */}
            <li><a href='/contact'>Contact</a></li>
          </ul>
        </div>
      </div>

      <div className='app_footer_contact_container'>
        <ul className='footer_contact_items'>
          
          <li><a href="mailto:info@mihlf.com">Something else? Email me <TfiEmail /></a></li> 
          <li><a href="tel:+12052010709"><FaPhoneAlt /> Phone</a></li>
          {/* <li><a href="https://www.instagram.com/iamronikacotton">Instagram <FaInstagram /></a></li> */}
          <li><a href="https://www.linkedin.com/in/alesia-jones-700248a/">LinkedIn <GrLinkedinOption /></a></li>
          <li className='copyright'>© 2024 AMJ HR Solutions, LLC.</li>
          <li className='design_tag'>Designed by: <a href="https://www.dotcomdv.com">dotcom</a></li>
        </ul>
      </div>

      <div className='app_footer_contact_banner'>
        <h1 className='app_contact_header'><a href="#home">HR done right.</a></h1>
        <a href="/"><img src={images.amjlogo} alt="logo" /></a>
      </div>

    </div>
  )
}

export default Footer
